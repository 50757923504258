<template>
  <div :class="`SectionTitle SectionTitle-${capitalize(direction)}`">
    <!-- eslint-disable vue/no-v-html -->
    <h2
      class="SectionTitle--Title"
      v-html="title"
    />
    <!-- eslint-enable vue/no-v-html -->
    <p class="SectionTitle--Subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { capitalize } from '~/domains/utils/helpers/utils.helper';
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface';

defineProps<{
  block: ContentBlock
  title: string
  subtitle: string
}>();

const direction = /*props.block.direction || */"center";

</script>

<style lang="scss">
@import "./style";
</style>
