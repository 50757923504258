<template>
  <section
    v-if="props.block.result.hits?.length || isLoading"
    class="HitList"
  >
    <SectionTitle
      :block="block"
      :title="props.block.text.title"
      :subtitle="props.block.text.subtitle"
    />
    <Hits
      :id="id"
      :index-name="props.block.searchable?.indexName"
      :nb-hits="props.block.result?.nbHits"
      :mode="HitModes.HIT_LIST"
      :hits="hitsSliced"
      :is-loading="isLoading"
      @show-all="navigateFromOutsideSearch(props.block.text?.showAllQueryParams)"
    />
  </section>
</template>

<script setup lang="ts">
import { getIndexes, HitModes, getMicroData } from '@/helpers'
import useConfigEnv from '@/composables/useConfigEnv'
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue'
import Hits from '@/components/Search/Hits.vue'
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import useSearch from '~/domains/search/composables/search.composable'

const props = defineProps<{
  id: string
  block: ContentBlock
  isLoading?: boolean
}>()

// composables
const configEnv = useConfigEnv()
const store = useStore()
const { navigateFromOutsideSearch } = useSearch()

// computed
const hitsSliced = computed(() => props.block.result.hits.slice(0, props.block.itemNumber || 11))

// methods
const fetchMicrodata = () => {
  const json = []

  for (const hit of props.block.result.hits) {
    const microdata = getMicroData(hit, configEnv)
    if (microdata) {
      json.push(microdata)
    }
  }
  // Don't fetch an empty array
  if (json.length > 0) {
    // Max. 6 @Event microdata objects for each carousel
    store.dispatch('forms/fetchMicrodata', json.slice(0, 6))
  }
}

// hooks
onMounted(() => {
  if (
    props.block.searchable?.indexName ===
    getIndexes(configEnv).activities
  ) {
    fetchMicrodata()
  }
})
</script>
