<template>
  <div
    :class="[
      'Thematic--Hero',
      {
        WithDescription: props.block.text?.description
      }
    ]"
    :style="
      variables.isUkraine.value
        ? `background-image: url('${ukraineBanner}')`
        : ''
    "
  >
    <div class="Container">
      <Breadcrumbs :block="props.block" />
      <template v-if="hasCapsuleDisplay">
        <div
          v-if="
            props.block.text?.logo &&
            !props.block.text?.logo.match(/[{|}]/)
          "
          class="Thematic--Logo"
        >
          <img
            :src="props.block.text?.logo"
            alt=""
          />
        </div>
        <div class="Thematic--Banner">
          <!-- Temoprary computed while Ukraine image is not in algolia/blob -->
          <img
            v-if="variables.banner.value"
            :src="variables.banner.value"
            alt=""
          />
          <img
            v-if="variables.isAvecLesPompiers.value"
            :src="avecLesPompiersBanner"
            alt=""
          />
        </div>
      </template>
      <div class="Thematic--Intro">
        <!-- eslint-disable vue/no-v-html -->
        <h1
          class="Thematic--IntroTitle"
          v-html="props.block.text?.title"
        />
        <div
          v-if="props.block.text?.description"
          class="Thematic--IntroDescription"
          v-html="props.block.text?.description"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div class="Thematic--IntroShare">
          <p>{{ $t('share.sharePage') }} :</p>
          <Socials
            :whatsapp-text="socialsText"
            :twitter-text="socialsText"
            :linkedin-text="socialsText"
            :mailto-subject="mailtoSubject"
            :mailto-body="mailtoBody"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, useI18n } from '#imports'
import { useRoute } from 'vue-router'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import Socials from '@/components/Socials/Socials.vue'
import useConfigEnv from '@/composables/useConfigEnv'
import type { ContentBlock } from '@/domains/thematic/interfaces/content.interface'
import useSeoBlock from '~/domains/thematic/composables/seoBlock.composable'

const props = defineProps<{
  block: ContentBlock
}>()

const configEnv = useConfigEnv()
const route = useRoute()
const { variables } = useSeoBlock()

const i18n = useI18n()
const metaTitle = ref(null)
const metaDescription = ref(null)
const url = computed(() => {
  return configEnv.NUXT_ENV_BASE_URL + route.path
})

const hasCapsuleDisplay = computed(() => {
  return (
    variables.banner.value ||
    variables.isUkraine.value ||
    variables.isAvecLesPompiers.value
  )
})

// // TODO make banner generic from configuration
const ukraineBanner = computed(() => {
  return '/_ex/thematic/events/ukraine.png'
})
const avecLesPompiersBanner = computed(() => {
  return '/_ex/thematic/events/aveclespompiers.jpg'
})

const socialsText = computed(
  () => variables.metaDescription.value || metaDescription.value
)

const mailtoSubject = computed(() => {
  return metaTitle.value
})
const mailtoBody = computed(() => {
  return i18n.t(
    'organization.contact.socials.socialNetwork.mailTo.body',
    [metaDescription, url]
  )
})

onMounted(() => {
  const titleSelector = document.querySelector(
    "meta[property='og:title']"
  )

  const descriptionSelector = document.querySelector(
    "meta[property='og:description']"
  )

  metaTitle.value =
    titleSelector && titleSelector.getAttribute('content')

  metaDescription.value =
    descriptionSelector && descriptionSelector.getAttribute('content')
})
</script>

<style lang="scss">
@import './style';
</style>
