<template>
  <section class="Sitemap Sitemap OblongRef">
    <h2 class="Sitemap--Title">
      {{ $t('sitemap.title') }}
    </h2>
    <p class="Sitemap--Subtitle">
      {{ $t('sitemap.subtitle') }}
    </p>
    <!-- Categories -->
    <div
      v-if="!isEmpty(otherCategories)"
      class="Sitemap--Links Sitemap--Links-Categories"
    >
      <h3 class="Sitemap--ListName h4">
        {{ $t('sitemap.categories.inThisCategory') }}
      </h3>
      <ul class="Sitemap--List">
        <li
          v-for="(otherCategory, index) in otherCategories"
          :key="index"
        >
          <ha-button
            v-if="otherCategory"
            :href="getCategoryUrl(otherCategory.tag_label)"
            variant="link"
            color="basic"
            :data-ux="`Explore_LP_Sitemap_otherCategories_${
              hasCategoryInUrl
                ? otherCategory.tag_label
                : otherCategory
            }`"
          >
            {{
              otherCategory.tag_label
            }}
          </ha-button>
        </li>
      </ul>
    </div>
    <!-- Activities -->
    <div
      v-if="!isEmpty(otherActivities)"
      class="Sitemap--Links Sitemap--Links-Activities"
    >
      <h3 class="Sitemap--ListName h4">
        {{ $t('sitemap.activities.byActivityType') }}
      </h3>
      <ul class="Sitemap--List">
        <li
          v-for="(otherActivity, index) in otherActivities"
          :key="index"
        >
          <ha-button
            :href="getActivityUrl(index)"
            variant="link"
            color="basic"
            :data-ux="`Explore_LP_Sitemap_OtherActivities_${otherActivity}`"
          >
            {{ otherActivity }}
          </ha-button>
        </li>
      </ul>
    </div>
    <!-- Places -->
    <div
      v-if="props.block.showDepartments || props.block.showCities"
      class="Sitemap--Links Sitemap--Links-Places"
    >
      <template
        v-if="
          props.block.showDepartments && !isEmpty(otherDepartments)
        "
      >
        <h3 class="Sitemap--ListName h4">
          {{ $t('sitemap.geo.otherDepartments') }}
        </h3>
        <ul class="Sitemap--List">
          <template
            v-for="(otherDepartment, index) in otherDepartments"
          >
            <li
              v-if="otherDepartment.place_department"
              :key="index"
            >
              <ha-button
                :href="getDepartmentUrl(otherDepartment)"
                variant="link"
                color="basic"
                :data-ux="`Explore_LP_Sitemap_OtherDepartments_${otherDepartment.place_department}`"
              >
                {{ otherDepartment.place_department }}
              </ha-button>
            </li>
          </template>
        </ul>
      </template>
      <template
        v-if="props.block.showCities && !isEmpty(otherCities)"
      >
        <h3 class="Sitemap--ListName h4">
          {{ $t('sitemap.geo.otherCities') }}
        </h3>
        <ul class="Sitemap--List">
          <li
            v-for="(otherCity, index) in otherCities"
            :key="index"
          >
            <ha-button
              :href="getCityUrl(otherCity)"
              variant="link"
              color="basic"
              :data-ux="`Explore_LP_Sitemap_OtherCities_${otherCity.place_city}`"
            >
              {{ otherCity.place_city }}
            </ha-button>
          </li>
        </ul>
      </template>
    </div>
  </section>
</template>

<script setup lang="ts">
import { isEmpty, orderBy } from 'lodash-es'
import { HaButton } from '@ha/components-v3'
import { computed, ref, useAsyncData, useNuxtApp, useRequestURL } from '#imports'
import {
  ActivityTypes,
  Categories,
  getSeoCleanedData
} from '@/helpers'
import useConfigEnv from '@/composables/useConfigEnv'
import type { ContentBlock } from '../../interfaces/content.interface'
import { SEARCH_ALL_INDEX, type HASearchResponse } from '~/domains/search/interfaces/search.interface'

import { aggregateSearches } from '../../helpers/query.helper'
import { getVariablesStatic } from '../../helpers/block.helper'
import useSeoBlock from '../../composables/seoBlock.composable'

const props = defineProps<{
  block: ContentBlock
}>()

const configEnv = useConfigEnv()
const { variables } = useSeoBlock()
const { $algoliaClient } = useNuxtApp()

const otherCategories = ref(null) // either object or array
const otherActivities = ref({})
const otherDepartments = ref([])
const otherCities = ref([])

const hasParentCategory = computed(() => {
  return !!variables.parentCategory.value
})

const hasCategoryInUrl = computed(() => {
  return !!variables.category.value
})

const env = computed(() => {
  return configEnv.NUXT_ENV_ENVIRONMENT
})

const getOtherPlaces = (searchable, order) => {
  const hits = searchable.hits

  return orderBy(hits, [order], ['asc']).map(
    ({ place_region, place_department, place_city }) => ({
      place_region,
      place_department,
      place_city
    })
  )
}

const getOtherCategories = (searchable) => {
  const hits = searchable.hits

  return orderBy(hits, ['tag_label'], ['asc']).map(
    ({ tag_label }) => ({
      tag_label
    })
  )
}

const urlPathname = useRequestURL().pathname

const getCategoryUrl = (category) => {

  // Remove last "/" of url if any by the user
  let url = urlPathname?.replace(/\/$/, '')

  // Remove "/act/<activityType>" after /e/
  if (variables.activityType.value) {
    url = url?.replace(
      `/act/${getSeoCleanedData(variables.activityType.value)}`,
      ''
    )
  }

  const seoCategory = getSeoCleanedData(variables.category.value)
  // Replace category in url by the new one
  if (url?.includes(seoCategory)) {
    return url?.replace(
      seoCategory,
      getSeoCleanedData(category)
    )
  }

  return `${url}/cat/${getSeoCleanedData(category)}`
}
const getActivityUrl = (activityTypeKey) => {

  let url = urlPathname?.replace(/\/$/, '')

  // Remove "/cat/<category>" after /e/
  if (variables.category.value) {
    url = url?.replace(`/cat/${getSeoCleanedData(variables.category.value)}`, '')
  }

  const seoActivity = getSeoCleanedData(variables.activityType.value)
  // Replace current activity on /act/ pages by the wanted one
  if (seoActivity) {
    url = urlPathname?.replace(
      seoActivity,
      activityTypeKey
    )

    return url
  }

  return `${url}/act/${activityTypeKey}`
}
const getDepartmentUrl = (location) => {
  const region = getSeoCleanedData(location.place_region)
  const department = getSeoCleanedData(location.place_department)

  const departmentURL = `/e/reg/${region}/dep/${department}`

  if (variables.activityType.value) {
    return `${departmentURL}/act/${variables.seoActivityType.value}`
  }

  if (variables.category.value) {
    return `${departmentURL}/cat/${variables.seoCategory.value}`
  }

  return departmentURL
}
const getCityUrl = (location) => {
  const region = getSeoCleanedData(location.place_region)
  const department = getSeoCleanedData(location.place_department)
  const city = getSeoCleanedData(location.place_city)

  const cityURL = `/e/reg/${region}/dep/${department}/ville/${city}`

  if (variables.activityType.value) {
    return `${cityURL}/act/${variables.seoActivityType.value}`
  }

  if (variables.category.value) {
    return `${cityURL}/cat/${variables.seoCategory.value}`
  }

  return cityURL
}

const results = ref([])

const initSiteMap = async () => {
  const staticVariables = getVariablesStatic(variables)
  const searchables = aggregateSearches(
    props.block.showCities,
    props.block.showDepartments,
    staticVariables.category,
    staticVariables.parentCategory,
    staticVariables.city,
    staticVariables.department,
    staticVariables.region,
  )

  const { data } = await useAsyncData<HASearchResponse[]>(
    'sitemap',
    // @ts-expect-error - Le typage du plugin ne semble pas pris en compte.
    () => $algoliaClient.instance.fetch(searchables)
  )

  results.value = data.value || []

  
  const categories = results.value.find((result) => {
    return result.index === `${env.value}_${SEARCH_ALL_INDEX.TAGS}`
  })

  const departments = results.value.find((result) => {
    return (
      result.index === `${env.value}_${SEARCH_ALL_INDEX.CITIES}` &&
      result?.hits[0]?.empty_params.includes('place_city')
    )
  })

  const cities = results.value.find((result) => {
    return result.index === `${env.value}_${SEARCH_ALL_INDEX.CITIES}`
  })

  otherCategories.value = categories
    ? getOtherCategories(categories)
    : Object.entries(Categories).map(([k,v]) => ({
      tag_label: v,
  }))
  
  if (hasParentCategory.value) {
    otherCategories.value = [
      { tag_label: variables.parentCategory.value },
      ...otherCategories.value
    ]
  }

  otherActivities.value = { ...ActivityTypes }
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete otherActivities.value[variables.activityType.value]

  otherDepartments.value = departments
    ? getOtherPlaces(departments, 'place_department')
    : []

  // Case of /ville/city url => most active cities of department without the current city
  // Other cases => most active cities of department
  // TODO: evaluate lat long case => geoloc accepted or not and use getNormalizedLocation
  otherCities.value = cities
    ? getOtherPlaces(cities, 'place_city')
    : []
}

await initSiteMap()

// await onMounted(initSiteMap)
</script>

<style lang="scss">
@import './style';
</style>
