export function get<
  TObj,
  TPath extends string,
  TDefault
>(obj: TObj, path: TPath, defaultValue?: TDefault) {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const upperFirst = (string: string): string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export const capitalize = (string: string): string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
