<template>
  <nav
    class="Breadcrumbs"
    :aria-label="$t('breadcrumbs.label')"
  >
    <ul class="Breadcrumbs--List">
      <li class="Breadcrumbs--ListItem">
        <ha-button
          href="/"
          class="Breadcrumbs--ListItemLink"
          variant="link"
          color="basic"
          data-ux="Explore_LP_Breadcrumbs_Home"
        >
          {{ $t('breadcrumbs.home') }}
        </ha-button>
      </li>
      <li
        v-for="(param, index) in params"
        :key="index"
        class="Breadcrumbs--ListItem"
      >
        <span class="Breadcrumbs--ListItemDivider">
          <HaIcon :icon="faChevronRight" />
        </span>
        <ha-button
          :to="href(param)"
          :class="[
            'Breadcrumbs--ListItemLink',
            {
              'Breadcrumbs--ListItemLink-Current':
                index === params.length - 1
            }
          ]"
          variant="link"
          color="basic"
          :aria-current="index === params.length - 1 ? 'page' : false"
          :data-ux="dataUx(param)"
        >
          {{ label(param) }}
        </ha-button>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { HaButton, HaIcon } from '@ha/components-v3'
import { computed, ref, useHead, useRoute, useJsonld, useI18n } from '#imports'
import type {
  ContentBlock,
  SeoMicrodata
} from '~/domains/thematic/interfaces/content.interface'
import type { MetaObject } from 'nuxt/schema'
import { forgeMicrodata } from '~/domains/thematic/helpers/block.helper'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import useSeoBlock from '~/domains/thematic/composables/seoBlock.composable'
import type { JsonLD } from '@@/node_modules/nuxt-jsonld/dist/types/index.d.d.ts'

const props = defineProps<{
  block: ContentBlock
}>()

const i18n = useI18n()
const route = useRoute()
const { variables } = useSeoBlock()
const meta = ref(props.block.seoData?.meta)
const microdata = ref(props.block.seoData?.microdata)

const metaImageUkraine = computed(() => {
  try {
    return '/_ex/thematic/events/ukraine-meta.jpg'
  } catch {
    return null
  }
})
const metaImagePompiers = computed(() => {
  try {
    return '/_ex/thematic/events/aveclespompiers-meta.jpg'
  } catch {
    return null
  }
})
const params = computed(() => {
  const keys = Object.keys(route.params)
  if (variables.parentCategory.value) {
    const lastKey = keys[keys.length - 1]
    return [
      ...keys.slice(0, keys.length - 1),
      'category_tags_n2',
      lastKey
    ]
  }
  return keys
})

/**
 * @param {'og'|'twitter'} metaType Defines value belonging.
 * @return {string} Returns a path.
 */
const chooseMetaImage = (metaType: string = ''): string => {
  if (variables.isUkraine.value) {
    return metaImageUkraine.value
  }

  if (variables.isAvecLesPompiers.value) {
    return metaImagePompiers.value
  }

  switch (metaType) {
    case 'og':
      return (
        variables.banner.value ||
        'https://www.helloasso.com/screen-home.jpg'
      )
    case 'twitter':
      return (
        variables.banner.value ||
        'https://www.helloasso.com/screen-home-twitter.jpg'
      )
    default:
      return null
  }
}

const head = (): MetaObject => {
  let path = route.path

  if (path.charAt(path.length - 1) === '/') {
    path = path.substring(0, path.length - 1)
  }

  const title = variables.metaTitle.value || meta.value.title
  const description =
    variables.metaDescription.value || meta.value.description

  return {
    title,
    link: [
      {
        rel: 'canonical',
        href: `https://www.helloasso.com${path}`
      }
    ],
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: description
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: title
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: chooseMetaImage('og')
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: title
      },
      {
        hid: 'twitter:image',
        property: 'twitter:image',
        content: chooseMetaImage('twitter')
      }
    ]
  }
}

const getMicrodata = (params: SeoMicrodata): JsonLD => {
  const data = forgeMicrodata(
    params,
    variables.hasParentCategory.value
  )
  return [
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      name: i18n.t('microdata.breadcrumbListName'),
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': 'https://helloasso.com',
            url: 'https://helloasso.com',
            name: 'HelloAsso'
          }
        },
        ...data
      ]
    }
  ]
}

const label = (value: string): string => {
  const labels = {
    activity_type: variables.activityType.value,
    category_tags: variables.category.value,
    category_tags_n2: variables.parentCategory.value,
    place_region: variables.region.value,
    place_department: variables.department.value,
    place_city: variables.city.value,
    ha_tags: variables.haTag.value
  }
  return labels[value]
}

const href = (value: string) => {
  const hrefs = {
    activity_type: `act/${variables.seoActivityType.value}`,
    category_tags: `cat/${variables.seoCategory.value}`,
    category_tags_n2: `cat/${variables.seoParentCategory.value}`,
    place_region: `reg/${variables.seoRegion.value}`,
    place_department: `reg/${variables.seoRegion.value}/dep/${variables.seoDepartment.value}`,
    place_city: `reg/${variables.seoRegion.value}/dep/${variables.seoDepartment.value}/ville/${variables.seoCity.value}`,
    tag_label: `evt/${variables.seoHaTag.value}`,
    ha_tags: `evt/${variables.seoHaTag.value}`
  }
  const paramsCopy = [...params.value]
  const places = ['place_region', 'place_department', 'place_city']

  if (
    paramsCopy.some((param: string) => places.includes(param)) &&
    !value.includes('place_')
  ) {
    const currentLocation = [...paramsCopy]
      .reverse()
      .find((param) => param.includes('place_'))
    return `/e/${hrefs[currentLocation]}/${hrefs[value]}`
  }
  return `/e/${hrefs[value]}`
}

const dataUx = (value: string) => {
  const prefix = 'Explore_LP_Breadcrumbs'
  const dataUxes = {
    activity_type: `${prefix}_ActivityType_${variables.activityType.value}`,
    category_tags: `${prefix}_Category_${variables.category.value}`,
    category_tags_n2: `${prefix}_Category_${variables.parentCategory.value}`,
    place_region: `${prefix}_Region_${variables.region.value}`,
    place_department: `${prefix}_Department_${variables.department.value}`,
    place_city: `${prefix}_City_${variables.city.value}`,
    ha_tags: `${prefix}_Event_${variables.haTag.value}`
  }
  return dataUxes[value]
}

useJsonld(getMicrodata(microdata.value))

useHead(head())
</script>

<style lang="scss">
@import './style';
</style>
