<template>
  <ha-tooltip class="HaTooltip-SocialNetworkButton">
    <template #trigger>
      <ha-button
        :class="`SocialNetworkButton SocialNetworkButton-${network.name}`"
        :data-ux="network.id"
        color="basic"
        variant="outline"
        size="large"
        @click="clickHandler"
      >
        <template #icon>
          <HaIcon :icon="network.icon" />
        </template>
      </ha-button>
    </template>
    <template #content>
      {{ displayLabelOrId() }}
    </template>
  </ha-tooltip>
</template>

<script setup lang="ts">
import { HaButton, HaIcon, HaTooltip, useNotifications } from "@ha/components-v3";
import { computed, useI18n } from "#imports";
import type { ContentNetwork } from "~/domains/thematic/interfaces/content.interface";

const props = defineProps<{
  network: ContentNetwork;
  isUrlCopy?: boolean;
  isGenericShare?: boolean;
}>();

const { pushNotification } = useNotifications();
const i18n = useI18n();

const shareableData = computed(() => {
  return {
    title: document.title,
    text: document.querySelector('meta[name="description"]').getAttribute("content"),
    url: props.network.link,
  };
});

function displayLabelOrId() {
  if (props.isUrlCopy) {
    return i18n.t("share.buttonLabel.copyUrl");
  }

  if (props.isGenericShare) {
    return i18n.t("share.buttonLabel.genericShare");
  }

  return props.network.name;
}

async function clickHandler() {
  if (props.isUrlCopy) {
    copyUrl();
    return;
  }
  if (props.isGenericShare) {
    await genericShare();
    return;
  }
  const popupWidth = 560;
  const popupHeight = 480;
  const popupXPosition =
    //@ts-expect-error availLeft and availTop are non standard properties
    window.screen.availLeft + (window.screen.availWidth - popupWidth) / 2;
  const popupYPosition =
    //@ts-expect-error availLeft and availTop are non standard properties
    window.screen.availTop + (window.screen.availHeight - popupHeight) / 2;

  window.open(
    props.network.link,
    "Share",
    `width=${popupWidth},height=${popupHeight},left=${popupXPosition},top=${popupYPosition}`
  );
}

function copyUrl() {
  navigator.clipboard.writeText(props.network.link);
  pushNotification({
    type: "success",
    title: i18n.t("toast.copyUrl.title"),
    body: i18n.t("toast.copyUrl.body"),
    timeout: 5000,
  });
}

async function genericShare() {
  await navigator.share(shareableData.value);
}
</script>

<style lang="scss">
@import "./style";
</style>
