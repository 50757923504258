<template>
  <section
    v-if="variables?.faq?.value?.length"
    class="faq__wrapper"
  >
    <h2>
      <abbr :title="$t('faq.fullWord')">{{ $t("faq.abbr") }}</abbr>
    </h2>
    <div class="faq__inner">
      <h3 v-if="props.block.subtitle">
        {{ props.block.subtitle }}
      </h3>
      <Accordion
        v-for="{ answer, question, id } of variables.faq.value"
        :key="id"
        :summary="question"
        :details="answer"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import Accordion from '@/components/Accordion/Accordion.vue'
import useSeoBlock from '~/domains/thematic/composables/seoBlock.composable';
const { variables } = useSeoBlock()
const props = defineProps({
  block: {
    type: Object,
    default: () => ({})
  }
})
</script>

<style lang="scss" scoped>
@import "./style";
</style>
