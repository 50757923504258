import { useRoute } from '#imports'
import { getPage, getPageTypeByFilters } from '../helpers/page.helper'
import { mapResultsBlocks } from '../helpers/search.helper'
import { getContentResult, getSearchableResults, getTagResult } from '../helpers/query.helper'
import useThematicStore from '../stores/thematic.store'
import { computeBlockContent, forgeMicrodata, getVariablesStatic } from '../helpers/block.helper'
import { get } from '~/domains/utils/helpers/utils.helper'
import { BLOCK_COMPONENT } from '../interfaces/content.interface'
import useSeoBlock from './seoBlock.composable'


export default function useThematic () {
  const thematicStore = useThematicStore()
  const { variables } = useSeoBlock()

  const route = useRoute()

  const initThematicPage = async () => {
    const pageType = getPageTypeByFilters(route.params)

    // Map result on searchable block
    const blockResults = getSearchableResults(thematicStore.getRawResults)

    // Map
    const { page: pageTemplate } = getPage(pageType, route.params)
    const { page } = mapResultsBlocks(pageTemplate, blockResults)


    // FORGE - replacing the dynamic content in the content pages with data from Algolia
    const contentResult = getContentResult(thematicStore.getRawResults)
    thematicStore.setSeoContent(contentResult?.hits?.[0])


    const tagResult = getTagResult(thematicStore.getRawResults)
    if (tagResult) {
      thematicStore.setParentCategory(tagResult.hits[0])
    }


    // Block paths at which we have content data to compute
    const pathsToCompute = ['text', 'seoData.meta', 'seoData.microdata.content', 'seoData.microdata.parentCategory']


    // Each block corresponds to a component in the page
    const variablesStatic = getVariablesStatic(variables)

    for (const block of page) {

      // compute default content block first
      computeBlockContent(block, variablesStatic, 'defaultText')

      for (const path of pathsToCompute) {

        const contentAtPath = get(block, path)
        if (contentAtPath) {
          if (Array.isArray(contentAtPath)) {
            contentAtPath.map((_mdBlock: any, index: number) => {
              computeBlockContent(block, variablesStatic, path + '.' + index, block.defaultText)
            })
          }
          else {
            computeBlockContent(block, variablesStatic, path, block.defaultText)
          }
        }
      }

      if (block.component === BLOCK_COMPONENT.CAROUSEL_GROUP) {
        for (const carousel of block.carousels) {
          computeBlockContent(carousel, variablesStatic, "text")
        }
      }

      // forge microdata
      const microdata = get(block, "seoData.microdata")
      const hasParentCategory = thematicStore.parentCategory !== null
      if (microdata) {
        forgeMicrodata(microdata, hasParentCategory)
      }
    }

    // return pageCompiled
    thematicStore.setPage(page)
    return page
  }

  return {
    initThematicPage
  }
}
