<template>
  <section v-if="!allCarouselsAreEmpty" class="CarouselGroup">
    <SectionTitle :block="block" :title="props.block.text.title" :subtitle="props.block.text.subtitle" />
    <Carousel
      v-for="(carousel, index) in carousels"
      :id="carousel.id"
      :key="index"
      :block="carousel"
      @has-result="addCarouselsResult"
      @show-all="navigateFromOutsideSearch(carousel.text?.showAllQueryParams)"
    >
      <template #title>
        <h3>{{ carousel.text.title }}</h3>
      </template>
    </Carousel>
  </section>
</template>

<script setup lang="ts">
import SectionTitle from "@/components/SectionTitle/SectionTitle.vue";
import Carousel from "@/domains/search/components/Carousel/Carousel.vue";
import { computed, ref } from "vue";
import type { ContentBlock } from "~/domains/thematic/interfaces/content.interface";
import useSearch from '~/domains/search/composables/search.composable'

const props = defineProps<{
  block: ContentBlock;
}>();

defineEmits(['show-all'])

const carouselsResult = ref([]);
const carousels = ref(props.block.carousels);

const { navigateFromOutsideSearch } = useSearch()

// there's no direction key in any block + direction seems unused
// const direction = /*props.block.direction || */"center";

// unused
// const slug = props.block.slug;

// const titleSlug = computed(() => {
//   if (slug === "category_tags") {
//     return variables.category.value.toLowerCase();
//   }
//   return filters.value[slug];
// });

// const pluralTitleSlug = computed(() => {
//   const type = ActivityTypes[titleSlug.value];
//   return titleSlug.value === "financement"
//     ? "financements de projets"
//     : `${(type.toLowerCase())}s`;
// });

const allCarouselsAreEmpty = computed(() => {
  return (
    carouselsResult.value.length > 0 &&
    carouselsResult.value.every((carousel) => carousel.result?.nbHits === 0)
  );
});

function addCarouselsResult(carouselResult): void {
  carouselsResult.value.push(carouselResult);
}

</script>
