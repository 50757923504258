<template>
  <details>
    <summary>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="props.summary" />
      <HaIcon icon="chevron-down" />
    </summary>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="props.details" />
  </details>
</template>

<script setup>
const props = defineProps({
  summary: {
    type: String,
    default: ''
  },
  details: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss" scoped>
@import "./style";
</style>
