<template>
  <section class="SignUp">
    <div class="SignUp--Wrapper">
      <div class="SignUp--Text">
        <!-- eslint-disable vue/no-v-html -->
        <h3
          class="h4"
          v-html="props.block.text.title"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div>
          <template v-if="props.block.text.subtitle">
            <!-- eslint-disable vue/no-v-html -->
            <h5
              class="h5"
              v-html="props.block.text.subtitle"
            />
            <!-- eslint-enable vue/no-v-html -->
          </template>
          <template v-else>
            {{ $t('signUp.subtitle_01') }}
            <strong>{{ $t('signUp.subtitle_02') }}</strong>
            {{ $t('signUp.subtitle_03') }}
          </template>
        </div>
      </div>
      <ha-button
        :href="href"
        class="SignUp--Button"
        color="basic"
        data-ux="Explore_LP_Organization_SignUp"
        target="_blank"
        rel="noreferrer noopener"
        @click="signUp"
      >
        {{ $t('signUp.button') }}
        <span class="visually-hidden">
          {{ $t('accessibility.openNewWindow') }}
        </span>
      </ha-button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { HaButton, useTracking } from '@ha/components-v3'
import {
  computed,
  useConfigEnv,
  useRoute,
  useI18n
} from '#imports'
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface'
import useSeoBlock from '~/domains/thematic/composables/seoBlock.composable';
import { Referrers } from '~/helpers';

const props = defineProps<{
  block: ContentBlock
}>()

const configEnv = useConfigEnv()
const i18n = useI18n()
const route = useRoute()
const { tracking } = useTracking()
const { referrer } = useSeoBlock()

// TODO - refactor duplicated computed and functions (see InviteOrgToSignUp component)
const href = computed(() => {
  if (referrer.value && referrer.value in Referrers) {
    return `${configEnv.NUXT_ENV_AUTH_FRONT}/inscription?from=${Referrers[referrer.value]}`
  }
  return '/associations?from=explore'
})

const isEvt = computed(() => {
  return route.path.startsWith('/e/evt/')
})

const isSearchPage = computed(() => {
  return route.path.startsWith('/e/recherche')
})

const isOrgPage = computed(() => {
  return route.path.startsWith('/associations/')
})

const segmentCategory = computed(() => {
  if (isEvt.value) {
    return 'Page événementielle'
  }
  if (isSearchPage.value) {
    return 'Recherche'
  }
  if (isOrgPage.value) {
    return 'Page asso'
  }
  return 'Page SEO'
})

const signUp = () => {
  const url = configEnv.NUXT_ENV_BASE_URL + route.fullPath
  const trackInfo = {
    rubrik: 'Explore',
    category: segmentCategory.value,
    href: href.value,
    url,
    referrer: referrer.value
  }
  tracking.track('Explore Subscribe CTA', trackInfo)
}
</script>

<style lang="scss">
@import './style';
</style>
