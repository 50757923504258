<template>
  <section
    v-if="props.block.text.section3 && !props.block.text.section3.match(/[{|}]/)"
    class="SeoContent SeoContent-1"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div class="SeoContent--DynamicData">
      <div v-html="props.block.text.section3"></div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface'

const props = defineProps<{
  block: ContentBlock
}>()
</script>

<style lang="scss" scoped>
.SeoContent {
  &--DynamicData {
    padding-top: $ha-unit * 12; // 96px
    padding-bottom: $ha-unit * 12; // 96px
  }
}
</style>
