import { get, upperFirst } from "~/domains/utils/helpers/utils.helper";
import type { ContentBlock, SeoMicrodata } from "@/domains/thematic/interfaces/content.interface";
import type { BlockVariables, BlockVariablesStatic } from '../interfaces/block.interface';

// assemble page content with algolia content
export const computeBlockContent = (block: ContentBlock, variables: BlockVariablesStatic = {}, path: string, defaultContent: object = {}) => {
  const blockPart = get(block, path) || {};
  const blockPartKey = Object.keys(blockPart)
  if (blockPartKey.length) {
    for (const key of blockPartKey) {
      const regex = /{(.*?)}/g // eg. "Hi, {firstname} {lastname} !"
      const matches = blockPart[key].matchAll(regex);

      for (const match of matches) {
        const matchKeyString = match[0]
        const matchKey = match[1]
        const value = variables[matchKey] || defaultContent[matchKey] || '' // matchKeyString
        blockPart[key] = blockPart[key].replace(matchKeyString, value)

        if(value === '') {
          console.warn('[computeBlockContent] Missing block variable:', matchKeyString);
        }
      }
    }
  }
  return block
}

export const forgeMicrodata = (params: SeoMicrodata, hasParentCategory: boolean) => {
  const items = params?.content
  const levels = []
  let level = {}

  items.forEach((item) => {
    const index = items.indexOf(item) + 2
    const url = item?.url
    const name = upperFirst(item?.name)
    level = {
      '@type': 'ListItem',
      position: index,
      item: {
        '@id': url,
        url,
        name
      }
    }
    levels.push(level)
  })
  /**
   * Check if a name is present to avoid malformed microdata like "/e/<item>/cat/"
   */
  if (hasParentCategory && params.parentCategory?.name) {
    const url = params.parentCategory?.url
    const name = params.parentCategory?.name
    const parentLevel = {
      '@type': 'ListItem',
      position: levels.length + 1,
      item: {
        '@id': url,
        url,
        name
      }
    }
    // Add parent category before the child category
    levels.splice(levels.length - 1, 0, parentLevel)
    // Increment child category position as a result
    levels[levels.length - 1].position = levels.length + 1
  }
  return levels
}

export const getVariablesStatic = (variables: BlockVariables): BlockVariablesStatic => {
  const variablesStatic: BlockVariablesStatic = {}
  for (const variableKey in variables) {
    if (Object.hasOwn(variables, variableKey)) {
      variablesStatic[variableKey] = variables[variableKey].value;
    }
  }
  return variablesStatic
}
