<template>
  <section
    v-if="true || isReady || isLoading"
    class="AmountCard"
  >
    <div class="AmountCard--Numbers">
      <div
        :class="[
          'AmountCard--Number',
          { 'AmountCard--Number-Loading': !isReady }
        ]"
      >
        <div
          v-if="!isReady"
          class="AmountCard--Skeleton"
        >
          <div class="AmountCard--SkeletonLine" />
        </div>
        <span
          v-else
          class="AmountCard--NumberAmount"
        >
          {{ amount }}</span
        >
        <div class="AmountCard--NumberText">
          {{ $t('amountCard.already') }}
          <br />
          {{ $t('amountCard.collectedBy') }}
        </div>
      </div>
      <div
        :class="[
          'AmountCard--Number',
          { 'AmountCard--Number-Loading': !isReady }
        ]"
      >
        <div
          v-if="!isReady"
          class="AmountCard--Skeleton"
        >
          <div class="AmountCard--SkeletonLine" />
        </div>
        <span
          v-else
          class="AmountCard--NumberAmount"
        >
          {{ organizations }}
        </span>
        <div class="AmountCard--NumberText">
          <span class="Color-Primary">
            {{ $t('amountCard.organizations') }}
          </span>
          <br />
          {{ $t('amountCard.thanksToYourSupport') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { ContentBlock } from '~/domains/thematic/interfaces/content.interface'
import { computed, ref } from '#imports'
import { amountToLocaleFormattedEuros } from '~/helpers'
import useSeoBlock from '~/domains/thematic/composables/seoBlock.composable';

const props = defineProps<{
  block: ContentBlock
}>()

const { variables } = useSeoBlock()

const amount = ref('')
const organizations = computed(() => props.block.result.hits[0].organizations_count)
const isLoading = ref(false)

const isReady = computed(
  () => !isLoading.value && !!amount.value && !!organizations.value
)

const formatAmount = (collected_amount: number) =>
  amountToLocaleFormattedEuros(
    {
      /**
       ** Adding 900k in donations for SolidaritéUkraine
       ** (currently not available in Algolia)
       */
      amount: variables.isUkraine.value
        ? collected_amount + 9e7
        : collected_amount,
      areCents: true
    },
    'fr-FR',
    {
      maximumFractionDigits: 0
    }
  )

amount.value = formatAmount(
  props.block.result.hits[0].collected_amount
)
</script>

<style lang="scss">
@import './style';
</style>
