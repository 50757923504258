import {
  isFirstLetterAVowel,
  isFeminineActivityType,
  ActivityTypes,
  HaTag,
  Referrers
} from '@/helpers'
import { useStore } from 'vuex'
import { computed, navigateTo, useI18n } from '#imports'
import useThematicStore from '../stores/thematic.store'
import { convertFilterToParamThematic } from '../helpers/filters.helper'
import type { BlockVariables } from '../interfaces/block.interface'

export default function useSeoBlock() {
  // composables - app
  const i18n = useI18n()
  const store = useStore()

  // composables - domains
  const thematicStore = useThematicStore()

  // city
  const city = computed(() => thematicStore.location?.city)
  const seoCity = computed(() => city.value && convertFilterToParamThematic(city.value))
  const ofCity = computed(() => isFirstLetterAVowel(seoCity.value) ? "d'" : 'de ')

  // department
  const department = computed(() => thematicStore.location?.department)
  const seoDepartment = computed(() => department.value && convertFilterToParamThematic(department.value))
  const atDepartment = computed(() => department.value ? i18n.t(`departments.${seoDepartment.value}.at`) : undefined)
  const fromDepartment = computed(() => department.value ? i18n.t(`departments.${seoDepartment.value}.from`) : undefined)

  // region
  const region = computed(() => thematicStore.location?.region)
  const seoRegion = computed(() => region.value && convertFilterToParamThematic(region.value))
  const atRegion = computed(() => region.value ? i18n.t(`regions.${seoRegion.value}.at`) : undefined)
  const fromRegion = computed(() => region.value ? i18n.t(`regions.${seoRegion.value}.from`) : undefined)

  // activity type
  const activityType = computed(() => ActivityTypes[thematicStore.filters?.activity_type as ActivityTypes])
  const seoActivityType = computed(() => activityType.value && convertFilterToParamThematic(activityType.value))
  const toLowerActivityType = computed(() => activityType.value?.toLowerCase())
  const toActivityTypes = computed(() => thematicStore.filters?.activity_type ? i18n.t(`toActivityTypes.${thematicStore.filters?.activity_type}`) : undefined)
  const ofActivityType = computed(() => isFirstLetterAVowel(activityType.value as string) ? "d'" : 'de ')

  // category tags
  const category = computed(() => thematicStore.category ?? thematicStore.filters?.category_tags as string)
  const seoCategory = computed(() => category.value && convertFilterToParamThematic(category.value))
  const toLowerCategory = computed(() => category.value?.toLowerCase())
  const ofCategory = computed(() => isFirstLetterAVowel(category.value) ? "d'" : 'de ')

  // parent category tags
  const parentCategory = computed(() => thematicStore.parentCategory?.parent_label)
  const hasParentCategory = computed(() => thematicStore.parentCategory !== null)
  const seoParentCategory = computed(() => parentCategory.value && convertFilterToParamThematic(parentCategory.value))
  const ofParentCategory = computed(() => isFirstLetterAVowel(parentCategory.value) ? "d'" : 'de ')
  const toLowerParentCategory = computed(() => parentCategory.value?.toLowerCase())

  // ha tags
  const haTag = computed(() => thematicStore.filters?.ha_tags as string)
  const seoHaTag = computed(() => haTag.value && convertFilterToParamThematic(haTag.value))
  const ofHaTag = computed(() => isFirstLetterAVowel(haTag.value) ? "d'" : 'de ')

  // algolia content
  const title = computed(() => thematicStore.seoContent?.title)
  const description = computed(() => thematicStore.seoContent?.description)
  const logo = computed(() => thematicStore.seoContent?.logo)
  const banner = computed(() => thematicStore.seoContent?.banner)
  const metaTitle = computed(() => thematicStore.seoContent?.meta_title)
  const metaDescription = computed(() => thematicStore.seoContent?.meta_description)
  const faq = computed(() => thematicStore.seoContent?.faq)

  // specific thematic pages
  const isUkraine = computed(() => thematicStore.seoContent?.ha_tags?.includes("Solidarité Ukraine") ?? false)
  const isAvecLesPompiers = computed(() => thematicStore.seoContent?.ha_tags?.includes("Aveclespompiers") ?? false)

  // plurial and gender
  const pluralActivityType = computed(() => {
    switch (activityType.value) {
      case ActivityTypes.financement:
        return 'financements de projets'
      case ActivityTypes.jeu:
        return 'jeux'
      case ActivityTypes.cours:
        return `${activityType.value?.toLowerCase()}`
      default:
        return `${activityType.value?.toLowerCase()}s`
    }
  })
  const allWithGender = computed(() => {
    return isFeminineActivityType(activityType.value) ? 'Toutes' : 'Tous'
  })
  const organizationsWithGender = computed(() => {
    if (isFeminineActivityType(activityType.value)) {
      return 'associatives auxquelles'
    }
    return 'associatifs auxquels'
  })

  const section1 = computed(() => thematicStore.seoContent?.section1)
  const section2 = computed(() => thematicStore.seoContent?.section2)
  const section3 = computed(() => thematicStore.seoContent?.section3)

  const showAll = (paramsUrlString: string = '') => {
    if (paramsUrlString.startsWith('?') === false) {
      throw new Error(`'paramsUrlString' is not a valid query string, receive: ${paramsUrlString}`)
    }
    const url = `/e/recherche${paramsUrlString}`
    navigateTo(url)
  }

  const referrer = computed(() => {
    switch (true) {
      case !!city.value:
        return Referrers[city.value] || city.value
      case !!department.value:
        return Referrers[department.value] || department.value
      case !!region.value:
        return Referrers[region.value] || region.value
      case seoHaTag.value === HaTag.COMITES_DES_FETES:
        return Referrers[HaTag.FNCOF] || HaTag.FNCOF
      case seoHaTag.value === HaTag.SAPEURS_POMPIERS:
        return Referrers[HaTag.SAPEURS_POMPIERS] || HaTag.SAPEURS_POMPIERS
      case seoHaTag.value === HaTag.MEDECINS_DU_MONDE:
        return Referrers[HaTag.MEDECINS_DU_MONDE] || HaTag.MEDECINS_DU_MONDE
      case seoHaTag.value === HaTag.TELETHON:
        return Referrers[HaTag.TELETHON] || HaTag.TELETHON
      default:
        return 'Explore'
    }
  })

  const variables: BlockVariables = {
    city,
    ofCity,
    department,
    atDepartment,
    fromDepartment,
    region,
    atRegion,
    fromRegion,
    activityType,
    toLowerActivityType,
    pluralActivityType,
    toActivityTypes,
    ofActivityType,
    allWithGender,
    organizationsWithGender,
    category,
    toLowerCategory,
    ofCategory,
    parentCategory,
    hasParentCategory,
    toLowerParentCategory,
    ofParentCategory,
    haTag,
    ofHaTag,
    seoCity,
    seoDepartment,
    seoRegion,
    seoActivityType,
    seoCategory,
    seoParentCategory,
    seoHaTag,
    section1,
    section2,
    section3,
    isAvecLesPompiers,
    isUkraine,
    title,
    description,
    logo,
    banner,
    metaTitle,
    metaDescription,
    faq
  }

  return {
    i18n,
    store,
    showAll,
    variables,
    referrer
  }
}
