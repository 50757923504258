<template>
  <div class="Thematic">
    <div
      v-for="(block, index) in page"
      :key="index"
      :class="`Thematic--Block Thematic--Block-${
        block?.component && capitalizeFirstLetter(block?.component)
      }`"
    >
      <component
        :is="components[block.component]"
        v-if="block?.component"
        :id="block.component"
        :block="block"
        :class="[
          {
            Container: block.component !== blockComponent.HERO
          }
        ]"
      />
    </div>
    <ScrollToTop />
  </div>
</template>

<script setup lang="ts">
import { BLOCK_COMPONENT } from '../interfaces/content.interface'
import {
  computed,
  onMounted,
  shallowRef,
  useAsyncData,
  useConfigEnv,
  useJsonld
} from '#imports'
import { capitalizeFirstLetter } from '@/helpers'
// store
import useThematicStore from '../stores/thematic.store'
import { useStore } from 'vuex'
// components
import ScrollToTop from '@/components/ScrollToTop/ScrollToTop.vue'
import useThematic from '../composables/thematic.composable'
// block seo
import AmountCard from '@/components/AmountCard/AmountCard.vue'
import Carousel from '@/domains/search/components/Carousel/Carousel.vue'
import CarouselGroup from '@/domains/search/components/Carousel/CarouselGroup.vue'
import Hero from '@/components/Hero/Hero.vue'
import HitList from '@/domains/search/components/Carousel/HitList.vue'
import HowItWorks from '@/components/HowItWorks/HowItWorks.vue'
import SectionTitle from '@/components/SectionTitle/SectionTitle.vue'
import SeoContent1 from '@/components/SeoContent/SeoContent1.vue'
import SeoContent2 from '@/components/SeoContent/SeoContent2.vue'
import SeoContent3 from '@/components/SeoContent/SeoContent3.vue'
import SignUp from '@/components/SignUp/SignUp.vue'
import Sitemap from '../components/Sitemap/Sitemap.vue'
import Socials from '@/components/Socials/Socials.vue'
import Faq from '@/components/Faq/Faq.vue'
import { useTracking } from '@ha/components-v3'

const blockComponent = BLOCK_COMPONENT

const components = shallowRef({
  AmountCard,
  Carousel,
  CarouselGroup,
  Hero,
  HitList,
  HowItWorks,
  SectionTitle,
  SeoContent1,
  SeoContent2,
  SeoContent3,
  SignUp,
  Sitemap,
  Socials,
  Faq
})

const { initThematicPage } = useThematic()
const thematicStore = useThematicStore()
const store = useStore()
const { tracking } = useTracking()
const config = useConfigEnv()

const page = computed(() => thematicStore.getPage)

await useAsyncData('thematic', async () =>
  thematicStore.setPage(await initThematicPage())
)
useJsonld(store.getters['forms/getMicrodata'])

onMounted(() => {
  tracking.page({
    rubrik: 'Explore',
    context: 'Contributeur',
    category: 'Page SEO',
    url: window.location.href,
    title: '',
    version: config.PACKAGE_VERSION
  })
})
</script>

<style scoped lang="scss">
.Thematic--Block-HowItWorks {
  margin-top: 4em;
  margin-bottom: 4em;
  padding-top: 4em;
  padding-bottom: 4em;

  @media (width >= 900px) {
    // Oblongs are hidden under this size in HowItWorks section.
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 1em);
      height: 100%;
      top: 0;
      background: hsl(
        var(--ha-color-primary-h) var(--ha-color-primary-s)
          var(--ha-color-primary-l) / 5%
      );
      z-index: -1;
      border-radius: 0 100vw 100vw 0;
    }
  }
}
</style>
